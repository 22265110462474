<template lang="pug">
div.costs-container
  div.cost-tabs
    .container
      .row.align-items-center
        div.h5.mr-4.mb-0 {{ $t('financeFormSelect.text') }}:
        div.cost-tab(
          v-for="(item, key, index) in getSortedCosts"
          :key="item.id"
          :class="{'active': key === getPriceForm }"
          @click="setActive(key)"
        )
          i.checkmark(aria-hidden="true")
          div {{ $t(formatNameLocale(key)) }}
        div.cost-tab(
          v-if="(this.useDownPayments && downPaymentsByDistance.length | 0)"
          :class="{'active': currentTab === 'cash_payment' }"
          @click="setActiveCashPaymentTab('cash_payment')"
        )
          i.checkmark(aria-hidden="true")
          div {{ $t(formatNameLocale('Kontantköp')) }}
        BackCircle.back-circle(@click="$emit('clickBack')")
  div.costs-options
    .container
        component(
          v-show="key === currentTab"
          v-for="(item, key, index) in getSortedCosts"
          :key="item.id"
          :is="formatName(key)"
        )
        DownPayment(
          v-if="this.useDownPayments && (downPaymentsByDistance.length | 0)"
          :downPayments="downPaymentsByDistance"
          :showPrices="true"
          v-show="currentTab === 'cash_payment'"
        )
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations, } from 'vuex'

export default {
  components: {
    privaterental: () => import('./costs-components/privaterental'),
    privateleasing: () => import('./costs-components/privateleasing'),
    downpayment: () => import('./costs-components/downpayment'),
    corporaterental: () => import('./costs-components/corporaterental'),
    corporateleasing: () => import('./costs-components/corporateleasing'),
    vatcredit: () => import('./costs-components/vatcredit'),
    NextStepBtn: () => import('@/components/steps-faceless/NextStepBtn.vue'),
    BackCircle: () => import('@/components/steps-faceless/BackCircle.vue'),
    DownPayment: () => import('./costs-parts/downPayment'),
  },
  data: () => ({
    sortedCosts: {},
    sendCosts: true,
    currentTab: 'Private leasing'
  }),
  computed: {
    ...mapState('reseller', {useDownPayments: state => state.resellerInfo.settings?.useDownPayments }),
    ...mapState('product', [ 'vehicle', 'downPaymentsByDistance', 'selectedDistance']),
    ...mapState('filter', {
      financeForms: state => state.allFinanceForms,
    }),
    ...mapState('order', [
      'residual',
      'cashPayment',
      'vehicleCostId',
    ]),
    ...mapGetters('product', [
      'getDefaults',
      'getPriceForm',
      'fixedCostByDistanceAndMonth',
      'getResidualVisibility',
    ]),
    ...mapGetters('filter', [
      'getFinanceFormName',
    ]),
    ...mapGetters('steps', ['getSortedCosts']),
  },
  created(){
    if (!this.getPriceForm) this.updatePriceForm(this.getFinanceFormName);

    this.getCostsObj();
  },
  mounted() {
    this.setActive(this.getPriceForm)
  },
  watch: {
    vehicle: function () {
      if (!this.getPriceForm) {
        this.updatePriceForm(this.getFinanceFormName);
      }

      if (typeof this.isHasPriceData('allow_residual') === "boolean")
        this.updateResidualVisibility(this.isHasPriceData('allow_residual'))

      this.getCostsObj();
    },
  },
  methods: {
    ...mapActions('steps', [
      'updateSortedCosts',
    ]),
    ...mapActions('product', [
      'updatePriceForm',
      'updateResidualVisibility',
    ]),
    ...mapActions('reseller', [
      'updateBuilder',
    ]),
    ...mapActions('filter', [
      'updateFinanceFormId',
      'updateFinanceFormName',
    ]),
    ...mapMutations('product', ['setSelectedAccessories']),
    getCostsObj() {
      const availableForms = this.financeForms.length ?
        this.financeForms.reduce((arr, next) => arr.concat(next.name), [])
        : [];

      if (this.vehicle.costs) {
        let sortedCosts = {};

        this.vehicle.costs.data.forEach(item => {
          const name = item.finance_form;

          if (availableForms.includes(name)) {

            if (!(name in sortedCosts)) sortedCosts[name] = []

            sortedCosts[name].push(item);
          }
        })

        this.updateSortedCosts(sortedCosts);
      }
    },
    setActive(tabName) {
      this.$set(this.$data, 'currentTab', tabName)
      if (this.getPriceForm === tabName) return;

      this.updatePriceForm(tabName);
      this.setSelectedAccessories([]);

      const financeFormId = this.financeForms.find(item => item.name === tabName).id;

      this.updateBuilder(financeFormId);

      if (typeof this.isHasPriceData('allow_residual') === "boolean")
        this.updateResidualVisibility(this.isHasPriceData('allow_residual'))

      this.sendCosts = true
    },
    setActiveCashPaymentTab() {
      this.$set(this.$data, 'currentTab', 'cash_payment')
    },
    formatName(name) {
      return name.toLowerCase().replace(' ', '')
    },
    formatNameLocale(name) {
      return `steps.costs.${name.toLowerCase().replace(' ', '')}`
    },
    isHasPriceData(key) {
      if (!(this.vehicle.prices && this.vehicle.prices.data.length)) return;

      const pricesObj = this.vehicle.prices.data.find(item => item.finance_form === this.getPriceForm);

      return pricesObj ? pricesObj[key] : 0
    },
    getResidual() {
      const storeResidual = this.residual;
      const vehicleResidual = this.isHasPriceData('default_residual');

      const fixedCostResidual =
        this.fixedCostByDistanceAndMonth && this.fixedCostByDistanceAndMonth.residual ?
          this.fixedCostByDistanceAndMonth.residual : vehicleResidual;

      return storeResidual ? storeResidual : fixedCostResidual
    },
    getCashPayment() {
      const storeCashPayment = this.cashPayment;
      const defaultCashPayment = this.isHasPriceData('default_cash_payment');

      return storeCashPayment ? storeCashPayment : defaultCashPayment;
    },
    getVehicleId() {
      if (!Object.keys(this.getDefaults).length) return false;

      const cost = this.vehicle.costs.data
        .filter(item => item.finance_form === this.getPriceForm)
        .filter(item => (
          item.finance_form === this.getPriceForm &&
          item.months === this.getDefaults[this.getPriceForm].months &&
          item.distance === this.getDefaults[this.getPriceForm].distance
          ))
        .sort((a, b) => b.months - a.months)
        .reduce((accum, value) => accum.calculated_price < value.calculated_price ? accum : value);

      return cost.id
    },
  },
  updated() {
    const id = this.getVehicleId();

    if (!(this.sendCosts && id)) return;

    let data;

    if (['Down payment', 'Corporate leasing', 'VAT credit'].includes(this.getPriceForm)) {
      data = {
        'residual': this.getResidual(),
        'cashPayment': this.getCashPayment(),
        'vehicleCostId': id,
        'forceUpdate': true
      }
    } else {
      data = {'vehicleCostId': id}
    }

    this.$store.dispatch('order/saveAllFields', {'vehicleCostId': id})
    this.$store.commit('order/setVehicleCostId', id)

    this.$store.dispatch('product/updateVehicle', data).then(() => {
      this.sendCosts = false
    });
  }
}
</script>

<style lang="sass">
  @import '@/style/components/Costs.sass'

  .costs-container
    border-bottom: none
    box-sizing: border-box
    background: rgba(255, 255, 255, 0.75)
    backdrop-filter: blur(6px)
    position: absolute
    bottom: 0
    width: 100%
    left: 0
    z-index: 999
    min-height: 506px
    .radio-block-title
      font-size: 16px
    .costs-list-subtitle
      font-size: 22px
      color: #3A3939
    .custom-tooltip-price
      font-size: 22px
      line-height: 22px
    .vue-slider-dot-tooltip
      border-radius: 2px
      width: 212px
      padding: 14px 0
      text-align: center
    .vue-slider-dot-tooltip-inner
      color: #000000
      font-weight: 600
    .costs-list-slider
      width: 274px
      max-width: initial
      padding: 0
      margin-top: 100px
      .vue-slider-rail
        padding: 0 !important
        height: 1px !important
        background: rgba(58, 57, 57, 0.4)
        .vue-slider-process
          height: 8px !important
          background: #EFDF00
          top: -3px !important
    .costs-slider-cash-payment
      width: 100%
      max-width: 905px
      .vue-slider-marks
        min-height: 8px
        top: -3px

    .downpayment-sliders-wrapper
      display: flex
      justify-content: space-between
      margin-bottom: 30px
    .radio-block
      display: flex
      align-items: center
      justify-content: center
    .downpayments-costs
      .downpayment-prices
        text-align: left
        .price-container
          .big
            font-size: 22px
        .costs-list-subtitle
          text-align: left
          font-size: 18px
          font-weight: normal
    .purchase-step-btn
      right: 85px
      bottom: 85px !important
    .costs-list-subtitle
      display: block
      text-align: center
    .back-circle
      right: 20px
    .radio-block > div:nth-child(2)
      position: absolute
      bottom: -36px
      width: 100%
    .next-btn
      bottom: 50px
    .costs-list-price
      display: flex
      justify-content: right
      align-items: baseline
      margin-top: 30px
      .big
        font-size: 50px
        font-weight: bold
  .cost-tabs
    height: 60px
    display: flex
    background: #EFDF00
    align-items: center
    padding: 0 88px

  .costs-options
    padding: 48px 0 45px 0
    height: 400px
  .cost-tab
    padding-bottom: 4px
    margin-right: 80px
    cursor: pointer
    display: flex
    align-items: center
    font-size: 20px
    margin-right: 20px
    @media(min-width: 992px)
      margin-right: 80px
      font-size: 22px
    div
      border-bottom: 1px solid #000000
      margin-left: 10px
    i.checkmark
      width: 35px
      height: 35px
      display: inline-block
      border: solid 3px #3A3939
      border-radius: 50%
    &.active i.checkmark
      background: url(~@/assets/img/icon-checkmark.svg) no-repeat center
      background-size: 35px 35px
      border-color: transparent

  .contract-choicer-container .radio-block-input:checked + .radio-block
    border: 1px solid
    border-color: rgba(58, 57, 57, 0.4) !important
    background: #EFDF00
  .contract-choicer-container
    display: flex
    flex-wrap: wrap
    background: transparent
    width: auto
    justify-content: left
    .radio-block-info
      color: #000000
      span
        font-size: 18px
        margin-right: 4px
    .radio-block-title
      border-bottom: none
    label
      position: relative
      width: 210px
      height: 94px
      background: #F7F7F7
      border: 1px solid rgba(58, 57, 57, 0.4)
      box-sizing: border-box
      margin-left: -1px
      margin-bottom: 34px
  .costs-list-container-row
    display: flex
    justify-content: space-between
    height: 100%
</style>
